.box {
  /*height: 4812px;*/
  /*width: 2390px;*/
  height: auto;
  /*height: 100vh;*/
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .primary-sitemap {
  /*height: 4812px;*/
  height: auto;
  /*width: 2390px;*/
  left: 0;
  position: relative;
  top: 0;
  width: 100vw;
  /*height: 100vh;*/
}

.box .home {
  /*height: 4812px;*/
  height: auto;
  /*width: 2394px;*/
  /*height: 100vh;*/
  width: 100vw;
  position: relative;
}

.box .overlap-group {
  /*height: 4812px;*/
  /*width: 1440px;*/
  left: 0;
  /*position: absolute;*/
  position: relative;
  top: 0;
  /*height: 100vh;*/
  width: 100vw;
  height: auto;
}

.box .home-fullscreen {
  align-items: flex-start;
  background-color: #efe1d4;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  /*position: absolute;*/
  position: relative;
  top: 0;
}

.box .navbar {
  align-items: center;
  background-color: #fdf7f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 64px;
  position: relative;
  width: 100vw;
  height: 10vh;
}

.box .container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.box .group {
  height: 52px;
  margin-bottom: -0.15px;
  margin-left: -0.15px;
  margin-top: -0.15px;
  position: relative;
  width: 30.3px;
}

.box .column {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  justify-content: center;
  position: relative;
}

.box .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

a {
  color: #a04613;
}

.box .text-wrapper-2 {
  color: #a04613;
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.box .nav-link-dropdown {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.box .icon-instance-node {
  height: 24px;
  position: relative;
  width: 24px;
}

.box .actions {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.box .style-secondary-small-true-dark-mode-false-icon-position-no-icon {
  border-color: #a04613 !important;
  flex: 0 0 auto !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.box .style-secondary-instance {
  color: #a04613 !important;
  font-family: "Roboto", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.box .style-primary-small-true-dark-mode-false-icon-position-no-icon {
  background-color: #a04613 !important;
  border-color: unset !important;
  flex: 0 0 auto !important;
  margin-bottom: -2px !important;
  margin-right: -2px !important;
  margin-top: -2px !important;
}

.box .style-primary-small-instance {
  font-family: "Roboto", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.box .header {
  align-items: center;
  background-image: url(../../../static/img/header-5.png);
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 0 4px 4px #00000040;
  display: flex;
  height: 90vh;
  padding: 40px 64px;
  position: relative;
  width: 100vw;
}

.box .content-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 560px;
}

.box .medium-length-hero {
  align-self: stretch;
  color: #efe1d4;
  font-family: "Roboto", Helvetica;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 67.2px;
  margin-top: -1px;
  position: relative;
}

.box .lorem-ipsum-dolor {
  align-self: stretch;
  color: #efe1d4;
  font-family: "Roboto", Helvetica, ui-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  position: relative;
}

/*.box .content {*/
/*  align-items: flex-start;*/
/*  align-self: stretch;*/
/*  display: flex;*/
/*  flex: 0 0 auto;*/
/*  flex-direction: column;*/
/*  gap: 24px;*/
/*  position: relative;*/
/*  width: 100%;*/
/*}*/

.box .content-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

/*.box .content-3 {*/
/*  align-items: center;*/
/*  align-self: stretch;*/
/*  display: flex;*/
/*  flex: 0 0 auto;*/
/*  flex-direction: column;*/
/*  gap: 24px;*/
/*  position: relative;*/
/*  width: 100%;*/
/*}*/

.box .content-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.box .content-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.box .column-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.box .subheading {
  align-self: stretch;
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.box .heading {
  align-self: stretch;
  color: #a04613;
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  position: relative;
}

.box .column-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.box .text-suspendisse {
  align-self: stretch;
  color: #a04613;
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  margin-top: -1px;
  position: relative;
}

.box .actions-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  padding: 16px 0px 0px;
  position: relative;
}

.box .button-wrapper {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: #a04613;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  padding: 12px 24px;
  position: relative;
}

.box .button-2 {
  all: unset;
  box-sizing: border-box;
  color: #a04613;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.box .style-link-small {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.box .placeholder-lightbox {
  align-self: stretch;
  position: relative;
  width: 100%;
  height: 60vh;
}

.box .layout-2 {
  align-items: center;
  background-color: #fdf7f1;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 40px 64px;
  position: relative;
  width: 100%;
  height: 70%;
}

.box .layout{
  align-items: flex-start;
  background-color: #fff9f4;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 40px 64px;
  position: relative;
  width: 100vw;
}

.box .content-3{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.box .section-title {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 768px;
}

.box .subheading-2 {
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.box .p {
  color: #a04613;
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-left: -100px;
  margin-right: -100px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 968px;
}

.box .text {
  align-self: stretch;
  color: #a04613;
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
  text-align: center;
}

.box .row {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  justify-content: center;
  position: relative;
  width: 1312px;
}

.box .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.box .placeholder-image {
  align-self: stretch;
  height: 240px;
  position: relative;
  width: 100%;
}

.box .content-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 261px;
  position: relative;
  width: 100%;
}

.box .heading-2 {
  align-self: stretch;
  color: #a04613;
  font-family: var(--heading-desktop-h4-font-family);
  font-size: var(--heading-desktop-h4-font-size);
  font-style: var(--heading-desktop-h4-font-style);
  font-weight: var(--heading-desktop-h4-font-weight);
  letter-spacing: var(--heading-desktop-h4-letter-spacing);
  line-height: var(--heading-desktop-h4-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.box .text-2 {
  align-self: stretch;
  color: #a04613;
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  text-align: center;
}

.box .div-wrapper {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: #a04613;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 12px 24px;
  position: relative;
}

.box .img {
  align-self: stretch;
  height: 240px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.box .union {
  height: 210.41px;
  position: relative;
  width: 387.05px;
}

.box .gallery {
  align-items: center;
  background-color: #fdf7f1;
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: 70%;
  padding: 50px 64px;
  position: relative;
  width: 100vw;
}

.box .section-title-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 768px;
}

.box .heading-3 {
  align-self: stretch;
  color: #a04613;
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.box .placeholder-image-2 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.box .placeholder-image-3 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.box .footer {
  align-items: center;
  background-color: #efe1d4;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 64px;
  position: relative;
  width: 100%;
}

.box .content-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.box .logo {
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.box .text-wrapper-3 {
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.box .text-wrapper-4 {
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 97px;
}

.box .social-links {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.box .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.box .divider {
  align-self: stretch;
  background-color: #a04613;
  border: 1px solid;
  height: 1px;
  position: relative;
  width: 100%;
}

.box .div-3 {
  /*align-items: flex-start;*/
  /*display: inline-flex;*/
  /*flex: 0 0 auto;*/
  /*gap: 24px;*/
  /*position: relative;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
}

.box .div-3 .div-3-inner {
  display: flex;
  gap: 24px;
}

.box .element-relume-all {
  color: #a04613;
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.box .text-wrapper-5 {
  color: #a04613;
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.box .text-3 {
  color: #efe1d4;
  font-family: "Roboto", Helvetica;
  font-size: 56px;
  font-weight: 700;
  left: 720px;
  letter-spacing: 0;
  line-height: 67.2px;
  position: absolute;
  top: 744px;
  white-space: nowrap;
}

.box .text-4 {
  color: #000000;
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  left: 2390px;
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: absolute;
  text-align: center;
  top: 2540px;
  white-space: nowrap;
}
