.style-primary-small {
  background-color: var(--primitive-color-neutral-black);
  border: 1px solid;
  border-color: var(--primitive-color-neutral-black);
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  display: inline-flex;
  position: relative;
}

.style-primary-small .text-wrapper {
  color: var(--semantic-text-alternate);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.style-secondary {
  border: 1px solid;
  border-color: var(--primitive-color-neutral-black);
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  display: inline-flex;
  position: relative;
}

.style-secondary .button {
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.box {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  display: flex;
}

.box .primary-sitemap {
  width: 100vw;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
}

.box .home {
  width: 100vw;
  height: auto;
  position: relative;
}

.box .overlap-group {
  width: 100vw;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
}

.box .home-fullscreen {
  background-color: #efe1d4;
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: 0;
  left: 0;
}

.box .navbar {
  background-color: #fdf7f1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
  padding: 0 64px;
  display: flex;
  position: relative;
}

.box .container {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.box .group {
  width: 30.3px;
  height: 52px;
  margin-top: -.15px;
  margin-bottom: -.15px;
  margin-left: -.15px;
  position: relative;
}

.box .column {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.box .div {
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

a {
  color: #a04613;
}

.box .text-wrapper-2 {
  color: #a04613;
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.box .nav-link-dropdown {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.box .icon-instance-node {
  width: 24px;
  height: 24px;
  position: relative;
}

.box .actions {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.box .style-secondary-small-true-dark-mode-false-icon-position-no-icon {
  border-color: #a04613 !important;
  flex: none !important;
  margin-top: -1px !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
}

.box .style-secondary-instance {
  color: #a04613 !important;
  font-family: Roboto, Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.box .style-primary-small-true-dark-mode-false-icon-position-no-icon {
  border-color: unset !important;
  background-color: #a04613 !important;
  flex: none !important;
  margin-top: -2px !important;
  margin-bottom: -2px !important;
  margin-right: -2px !important;
}

.box .style-primary-small-instance {
  font-family: Roboto, Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  letter-spacing: 0 !important;
  margin-top: -1px !important;
  margin-left: -1px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.box .header {
  background-image: url("header-5.8f4317c7.png");
  background-position: 50%;
  background-size: cover;
  align-items: center;
  width: 100vw;
  height: 90vh;
  padding: 40px 64px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.box .content-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 560px;
  display: flex;
  position: relative;
}

.box .medium-length-hero {
  color: #efe1d4;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 56px;
  font-weight: 700;
  line-height: 67.2px;
  position: relative;
}

.box .lorem-ipsum-dolor {
  color: #efe1d4;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Roboto, Helvetica, ui-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  position: relative;
}

.box .content-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 80px;
  width: 100%;
  display: flex;
  position: relative;
}

.box .content-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
}

.box .content-6 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.box .column-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.box .subheading {
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.box .heading {
  color: #a04613;
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  align-self: stretch;
  position: relative;
}

.box .column-3 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 24px;
  display: flex;
  position: relative;
}

.box .text-suspendisse {
  color: #a04613;
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.box .actions-2 {
  flex: none;
  align-items: center;
  gap: 24px;
  padding: 16px 0 0;
  display: inline-flex;
  position: relative;
}

.box .button-wrapper {
  all: unset;
  box-sizing: border-box;
  border: 1px solid #a04613;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.box .button-2 {
  all: unset;
  box-sizing: border-box;
  color: #a04613;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Roboto, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.box .style-link-small {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.box .placeholder-lightbox {
  align-self: stretch;
  width: 100%;
  height: 60vh;
  position: relative;
}

.box .layout-2 {
  background-color: #fdf7f1;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 80px;
  width: 100%;
  height: 70%;
  padding: 40px 64px;
  display: flex;
  position: relative;
}

.box .layout {
  background-color: #fff9f4;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 80px;
  width: 100vw;
  padding: 40px 64px;
  display: flex;
  position: relative;
}

.box .content-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.box .section-title {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 768px;
  display: flex;
  position: relative;
}

.box .subheading-2 {
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.box .p {
  color: #a04613;
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  text-align: center;
  width: 968px;
  margin-top: -1px;
  margin-left: -100px;
  margin-right: -100px;
  position: relative;
}

.box .text {
  color: #a04613;
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.box .row {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  width: 1312px;
  display: flex;
  position: relative;
}

.box .div-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 32px;
  display: flex;
  position: relative;
}

.box .placeholder-image {
  align-self: stretch;
  width: 100%;
  height: 240px;
  position: relative;
}

.box .content-5 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 261px;
  display: flex;
  position: relative;
}

.box .heading-2 {
  color: #a04613;
  font-family: var(--heading-desktop-h4-font-family);
  font-size: var(--heading-desktop-h4-font-size);
  font-style: var(--heading-desktop-h4-font-style);
  font-weight: var(--heading-desktop-h4-font-weight);
  letter-spacing: var(--heading-desktop-h4-letter-spacing);
  line-height: var(--heading-desktop-h4-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.box .text-2 {
  color: #a04613;
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.box .div-wrapper {
  all: unset;
  box-sizing: border-box;
  border: 1px solid #a04613;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  display: inline-flex;
  position: relative;
}

.box .img {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 240px;
  position: relative;
}

.box .union {
  width: 387.05px;
  height: 210.41px;
  position: relative;
}

.box .gallery {
  background-color: #fdf7f1;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  width: 100vw;
  height: 70%;
  padding: 50px 64px;
  display: flex;
  position: relative;
}

.box .section-title-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 768px;
  display: flex;
  position: relative;
}

.box .heading-3 {
  color: #a04613;
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.box .placeholder-image-2 {
  object-fit: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.box .placeholder-image-3 {
  object-fit: cover;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.box .footer {
  background-color: #efe1d4;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 64px;
  display: flex;
  position: relative;
}

.box .content-7 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.box .logo {
  flex: 1;
  position: relative;
}

.box .text-wrapper-3 {
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.box .text-wrapper-4 {
  color: #a04613;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  width: 97px;
  margin-top: -1px;
  position: relative;
}

.box .social-links {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.box .credits {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.box .divider {
  background-color: #a04613;
  border: 1px solid;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.box .div-3 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  display: flex;
}

.box .div-3 .div-3-inner {
  gap: 24px;
  display: flex;
}

.box .element-relume-all {
  color: #a04613;
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.box .text-wrapper-5 {
  color: #a04613;
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  text-decoration: underline;
  position: relative;
}

.box .text-3 {
  color: #efe1d4;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 56px;
  font-weight: 700;
  line-height: 67.2px;
  position: absolute;
  top: 744px;
  left: 720px;
}

.box .text-4 {
  color: #000;
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 2540px;
  left: 2390px;
}
/*# sourceMappingURL=index.2eaea5e0.css.map */
